import { gql } from '@apollo/client';

let GRAPHQL_URI: string = 'https://bw-api-dev.onrender.com/';
let COOKIE_URL: string = '.batch.works'
let LOCAL: boolean = false

if (process.env.REACT_APP_BRANCH === 'beta') {
  GRAPHQL_URI = 'https://bw-api-beta.onrender.com/';
} else if (process.env.REACT_APP_BRANCH === 'local') {
  GRAPHQL_URI = 'http://localhost:8000/';
  LOCAL = true
}


console.log('backend: ', GRAPHQL_URI)
/* GRAPH QL INC. */
const PING = gql`
  query Ping {
    ping
  }
`;


/*****************************************************************/
/*                          PRODUCTS                             */
/*****************************************************************/

const GET_PRODUCTS = gql`
  query GetProducts($includeFiles: Boolean!, $includeMessages: Boolean!) {
    products {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      statusOptions
      latestVersion
      statusSeen
      lastModified
      productVersion {
        reference
      }
      createdBy {
          lastLogin
          isSuperuser
          firstName
          lastName
          isStaff
          isActive
          dateJoined
          userId
          email
          username
      }
      filemodelSet @include(if: $includeFiles) {
          fileId
          fileName
          file
          timestamp
          presignedUrl
          printable
      }
      messagemodelSet @include(if: $includeMessages) {
        messageId
        read
        sender {
          userId
        }
      }
    }
  }
`;

const GET_PRODUCTS_FOR_USER = gql`
  query GetProductsForUser($userId: ID!, $includeFiles: Boolean!, $includeMessages: Boolean!) {
    productsForUser(userId: $userId) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      statusOptions
      statusSeen
      latestVersion
      lastModified
      groupId {
        groupId
        groupTitle
      }
      organisations {
        organisationId
        name
      }
      productVersion {
        reference
      }
      createdBy {
          lastLogin
          isSuperuser
          firstName
          lastName
          isStaff
          isActive
          dateJoined
          userId
          email
          username
      }
      filemodelSet @include(if: $includeFiles) {
          fileId
          fileName
          file
          timestamp
          presignedUrl
          printable
      }
      messagemodelSet @include(if: $includeMessages) {
        messageId
        read
        sender {
          userId
        }
      }
    }
  }
`;

const GET_USERS_ORGANISATIONS_PRODUCTS = gql`
  query GetProductsForOrganisations {
    productsForOrganisations {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      statusOptions
      statusSeen
      latestVersion
      lastModified
      organisations {
        organisationId
        name
      }
      productVersion {
        reference
      }
      createdBy {
          lastLogin
          isSuperuser
          firstName
          lastName
          isStaff
          isActive
          dateJoined
          userId
          email
          username
      }
      filemodelSet {
          fileId
          fileName
          file
          timestamp
          presignedUrl
          printable
      }
      messagemodelSet {
        messageId
        read
        sender {
          userId
        }
      }
      groupId {
        groupId
        groupTitle
        defaultGroup
        createdBy {
          userId
        }
      }
    }
  }
`;

const GET_PRODUCTS_OF_GROUP = gql`
  query GetProductsOfGroup($groupId: ID!) {
    productsOfGroup(groupId: $groupId) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      statusOptions
      statusSeen
      latestVersion
      lastModified
      organisations {
        organisationId
        name
      }
      productVersion {
        reference
      }
      createdBy {
          lastLogin
          isSuperuser
          firstName
          lastName
          isStaff
          isActive
          dateJoined
          userId
          email
          username
      }
      filemodelSet {
          fileId
          fileName
          file
          timestamp
          presignedUrl
          printable
      }
      messagemodelSet {
        messageId
        read
        sender {
          userId
        }
      }
    }
  }
`;

const GET_PRODUCT = gql`
  query GetProduct($productId: ID!) {
    product(productId: $productId) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      lastModified
      latestVersion
      productVersion {
        versionId
        reference
        versionNumber
      }
      groupId {
        groupId
        createdBy {
          userId
        }
      }
      organisations {
        organisationId
        name
      }
      filemodelSet {
        fileId
        fileName
        file
        presignedUrl
        printable
        timestamp
        fileattributesmodelSet {
          printWeight
          printTime
          nozzleSize
          outOfBounds
        }
        costmodelSet {
          sellingPrice
          grossPrice
          marginPrice
          withDefaultParams
        }
      }
    }
  }
`;

const GET_MARKERS_FOR_FILE = gql`
  query MarkersForFile($fileId: ID!) {
    markersForFile(fileId: $fileId) {
      markerId
      xCoord
      yCoord
      zCoord
      message
      createdAt
      createdBy {
        userId
        firstName
        lastName
      }
    }
  }
`;

const GET_GROUPS = gql`
  query Groups {
    groups {
      groupId
      groupTitle
      organisations {
        organisationId
        name
      }
      defaultGroup
      createdBy {
        userId
      }
    }
  }
`;

const GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS = gql`
  query GroupsOfOrganisationsWithProducts {
    groupsOfOrganisationsWithProducts {
      groups {
        groupId
        groupTitle
        organisations {
          organisationId
          name
        } 
        defaultGroup
        createdBy {
          userId
        }
      }
      products {
        productId
        productTitle
        noOfUploadedFiles
        status
        description
        createdAt
        lastModified
        latestVersion
        productVersion {
          versionId
          reference
          versionNumber
        }
        groupId {
          groupId
        }
        organisations {
          organisationId
          name
        }
        filemodelSet {
          fileId
          fileName
          file
          presignedUrl
          printable
          timestamp
          fileattributesmodelSet {
            printWeight
            printTime
            nozzleSize
            outOfBounds
          }
          costmodelSet {
            sellingPrice
            grossPrice
            marginPrice
            withDefaultParams
          }
        }
      }
    }
  }
`;

const GET_GROUP = gql`
  query Group($groupId: ID!) {
    group(groupId: $groupId) {
      groupId
      groupTitle
      organisations {
        organisationId
        name
      }
      defaultGroup
      createdBy {
        userId
      }
    }
  }
`;

const UPDATE_GROUP = gql`
  mutation UpdateGroup($groupId: ID!, $name: String, $addToOrganisationId: ID, $removeFromOrganisationId: ID) {
    updateGroup(groupId: $groupId, name: $name, addToOrganisationId: $addToOrganisationId, removeFromOrganisationId: $removeFromOrganisationId) {
      success
      error
    }
  }
`

const CREATE_PRODUCT = gql`
  mutation CreateProduct($productTitle: String!, $description: String!) {
    createProduct(productTitle: $productTitle, description: $description) {
      error
      success
      createdProduct {
        productId
        productTitle
        noOfUploadedFiles
        status
        description
        createdAt
        productVersion {
          reference
        }
      }
    }
  }
`;

const UPLOAD_FILE_AND_PRODUCT = gql`
  mutation UploadFilesAndProduct($productTitle: String!, $files: [Upload]!, $description: String!, $groupId: Int ) {
    uploadFilesAndProduct(productTitle: $productTitle, files: $files, description: $description, groupId: $groupId) {
      errors
      success
      uploadedProduct {
          productId
          productTitle
          noOfUploadedFiles
          status
          description
          createdAt
          productVersion {
            reference
          }
          filemodelSet {
              fileId
              fileName
              file
              timestamp
              printable
          }
      }
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productId: ID!, $status: String, $groupId: Int, $description: String, $organisationId: ID) {
    updateProduct(productId: $productId, status: $status, groupId: $groupId, description: $description, organisationId: $organisationId) {
      errors
      success
    }
  }
`;

const PRODUCT_STATUS_SEEN = gql`
  mutation ProductStatusSeen($productId: ID!) {
    productStatusSeen(productId: $productId) {
        errors
        success
    }
  }
`;

const UPDATE_PRODUCT_WITH_FILES = gql`
  mutation UpdateProductWithFiles($files: [Upload]!, $productId: ID!) {
    updateProductWithFiles(files: $files, productId: $productId) {
      errors
      success
      uploadedFiles {
        fileId
        presignedUrl
      }
    }
  }
`;

const GET_PRODUCT_PREFERENCES = gql`
  query Preferences($preferencesId: ID!) {
    preferences(preferencesId: $preferencesId) {
      preferencesId
      layerWidth
      layerHeight
      infill
      nozzleSize
      indepthPreview
      vaseMode
      vaseModeBase
      filament {
        filamentId
        colour {
          colourId
          displayName
          hexCode
        }
        material {
          materialId
          materialName
        }
      }
    }
  }
`;

const GET_FILE_ATTRIBBUTES_FOR_FILE = gql`
  query FileAttributesForFile($fileId: ID!) {
    fileAttributesForFile(fileId: $fileId) {
      printWeight
      printTime
      nozzleSize
      outOfBounds
    }
  }
`

const CREATE_PART_PREFERENCES = gql`
  mutation CreatePartPreferences($productId: ID, $partId: ID, $indepthPreview: Boolean, $infill: Float, $layerHeight: Float, $layerWidth: Float, $nozzleSize: Float, $filament: ID, $vaseMode: Boolean, $vaseModeBase: Boolean) {
    createPartPreferences(productId: $productId, partId: $partId, indepthPreview: $indepthPreview, infill: $infill, layerHeight: $layerHeight, layerWidth: $layerWidth, nozzleSize: $nozzleSize, filament: $filament, vaseMode: $vaseMode, vaseModeBase: $vaseModeBase) {
        errors
        success
    }
}

`;

const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: ID!) {
      deleteProduct(productId: $productId) {
          success
      }
  }
`;

const DELETE_FILE = gql`
  mutation DeleteFile($fileId: ID!) {
      deleteFile(fileId: $fileId) {
          success
          errors
      }
  }
`;

const CREATE_GROUP = gql`
  mutation CreateGroup($groupTitle: String!) {
      createGroup(groupTitle: $groupTitle) {
          success
          errors
          createdGroup {
              groupId
              groupTitle
              defaultGroup
              createdBy {
                userId
              }
          }
      }
  }
`;

const DELETE_GROUP = gql`
  mutation DeleteGroup($groupId: ID!) {
    deleteGroup(groupId: $groupId) {
      success
      errors
    }
  }
`

const CREATE_MARKER_FOR_FILE = gql`
  mutation CreateMarker($fileId: ID!, $xCoordinate: Float!, $yCoordinate: Float!, $zCoordinate: Float!, $message: String) {
    createMarker(fileId: $fileId, xCoordinate: $xCoordinate, yCoordinate: $yCoordinate, zCoordinate: $zCoordinate, message: $message) {
      success
      errors
      newMarker {
        markerId
        xCoord
        yCoord
        zCoord
        message
        createdAt
        createdBy {
          userId
        }
      }
    }
  }
`;

const DELETE_MARKER = gql`
  mutation DeleteMarker($markerId: ID!) {
    deleteMarker(markerId: $markerId) {
      success
      errors
    }
  }
`;

const UPLOAD_PROTOTYPE = gql`
  mutation UploadPrototype($file: Upload!) {
    uploadPrototype(file: $file) {
        uploadedPrototype {
            prototypeId
            file {
              fileId
              fileName
              file
              timestamp
              presignedUrl
              validUntil
              printable
            }
        }
        success
        errors
    }
  }
`;


const CREATE_NEW_PRODUCT_VERSION = gql`
  mutation NewProductVersion($reference: UUID!, $files: [Upload], $carriedFiles: [ID], $versionTag: String) {
    newProductVersion(reference: $reference, files: $files, carriedFiles: $carriedFiles, versionTag: $versionTag) {
      success
      errors
      versionedProduct {
        versionId
        versionNumber
        versionTag
        productId
      }
    }
  }
`;


/*** VERSION RELATED ***/
const GET_VERSIONS_FOR_PRODUCT_REF = gql`
  query VersionsForProductRef($reference: UUID!) {
    versionsForProductRef(reference: $reference) {
      versionId
      versionNumber
      versionTag
      reference
      productId
    }
  }
`;

const GET_PRODUCT_BY_REF = gql`
  query ProductByRef($reference: UUID!, $versionNumber: Int) {
    productByRef(reference: $reference, versionNumber: $versionNumber) {
      productId
      productTitle
      noOfUploadedFiles
      status
      description
      createdAt
      createdBy {
        userId
        firstName
        lastName
      }
      lastModified
      latestVersion
      preferences {
        preferencesId
        layerWidth
        layerHeight
        vaseMode
        vaseModeBase
        nozzleSize
        infill
        indepthPreview
        filament {
          filamentId
          colour {
            colourId
            displayName
            hexCode
          }
          material {
            materialId
            materialName
            displayName
          }
        }
      }
      productVersion {
        versionId
        reference
        versionNumber
      }
      groupId {
        groupId
        groupTitle
        createdBy {
          userId
        }
      }
      organisations {
        organisationId
        name
      }
      filemodelSet {
        fileId
        fileName
        file
        presignedUrl
        printable
        timestamp
        fileattributesmodelSet {
          printWeight
          printTime
          nozzleSize
          outOfBounds
        }
        costmodelSet {
          sellingPrice
          grossPrice
          marginPrice
          withDefaultParams
        }
        fileslicermodelSet {
          isSlicing
        }
      }
    }
  }
`;

const FILE_SLICER = gql`
  query FileSlicer($stlFileId: ID!) {
    fileSlicer(stlFileId: $stlFileId) {
      id
      isSlicing
      slicingError
      stl {
        fileId
      }
      gcode {
        fileId
      }
      rotateX
      rotateY
      rotateZ
      nozzleDiameter
      layerHeight
      extrusionWidth
      bottomSolidLayers
      firstLayerHeight
      speed
      fanSpeed
      temperature
    }
  }
`

const RESLICE_FILE = gql`
  mutation ResliceFile($stlFileId: ID!, $productId: ID!, $rotateX: Float, $rotateY: Float, $rotateZ: Float, $nozzleDiameter: Float, $layerHeight: Float, $extrusionWidth: Float, $bottomSolidLayers: Float, $firstLayerHeight: Float, $speed: Float, $fanSpeed: Float, $temperature: Float) {
    resliceFile(stlFileId: $stlFileId, productId: $productId, rotateX: $rotateX, rotateY: $rotateY, rotateZ: $rotateZ, nozzleDiameter: $nozzleDiameter, layerHeight: $layerHeight, extrusionWidth: $extrusionWidth, bottomSolidLayers: $bottomSolidLayers, firstLayerHeight: $firstLayerHeight, speed: $speed, fanSpeed: $fanSpeed, temperature: $temperature) {
      success
      error 
    }
  }
`

const GET_EMISSIONS_FOR_FILE = gql`
  query EmissionsForGcode($fileId: ID!, $filamentId: ID!, $factoryId: ID!, $quantity: Int!) {
    emissionsForGcode(fileId: $fileId, filamentId: $filamentId, factoryId: $factoryId, quantity: $quantity) {
      total
      material
      materialTransport
      printing
    }
  }
`

const products = {
  queries: {
    GET_PRODUCTS,
    GET_PRODUCTS_FOR_USER,
    GET_USERS_ORGANISATIONS_PRODUCTS,
    GET_PRODUCT,
    GET_PRODUCT_BY_REF,
    GET_VERSIONS_FOR_PRODUCT_REF,
    GET_GROUPS,
    GET_GROUPS_OF_ORGANISATIONS_WITH_PRODUCTS,
    GET_GROUP,
    GET_PRODUCTS_OF_GROUP,
    GET_MARKERS_FOR_FILE,
    GET_PRODUCT_PREFERENCES,
    FILE_SLICER,
    GET_EMISSIONS_FOR_FILE,
    GET_FILE_ATTRIBBUTES_FOR_FILE
  },
  mutations: {
    CREATE_PRODUCT,
    UPLOAD_FILE_AND_PRODUCT,
    UPDATE_PRODUCT,
    PRODUCT_STATUS_SEEN,
    UPDATE_PRODUCT_WITH_FILES,
    DELETE_PRODUCT,
    DELETE_FILE,
    CREATE_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP,
    CREATE_MARKER_FOR_FILE,
    DELETE_MARKER,
    UPLOAD_PROTOTYPE,
    CREATE_NEW_PRODUCT_VERSION,
    CREATE_PART_PREFERENCES,
    RESLICE_FILE,
  } 
}

/*****************************************************************
                          ORGANISATIONS                               
*****************************************************************/

const GET_ORGANISATIONS = gql`
  query Organisations {
    organisations {
      organisationId
      name
      size
      productCount
      createdBy {
        userId
        username
        email
        firstName
        lastName
      }
      memberships {
        membershipId
        role {
          name
          level
        }
        user {
          userId
          username
          firstName
          lastName
        }
      }
     }
  }
`

const CREATE_ORGANISATION = gql`
  mutation CreateOrganisation($name: String!, $contactEmail: String!) {
    createOrganisation(name: $name, contactEmail: $contactEmail) {
      success
      error
      createdOrganisation {
        organisationId
        name
      }
    }
  }
`

const ADD_USER_TO_ORGANISATION = gql`
  mutation AddUserToOrg($organisationId: ID!, $userId: ID!, $roleName: String!) {
    updateOrganisation(organisationId: $organisationId, userId: $userId, roleName: $roleName) {
      success
      error
      updatedOrganisation {
        organisationId
        name
        memberships {
          membershipId
          user {
            userId
          }
        }
      }
    }
  }
`

const REMOVE_USER_FROM_ORGANISATION = gql`
  mutation RemoveUserFromOrg($organisationId: ID!, $userId: ID!) {
    updateOrganisation(organisationId: $organisationId, userId: $userId) {
      success
      error
    }
  }
`

const organisations = {
  queries: {
    GET_ORGANISATIONS
  },
  mutations: {
    CREATE_ORGANISATION,
    ADD_USER_TO_ORGANISATION,
    REMOVE_USER_FROM_ORGANISATION
  }
}


/*****************************************************************
                             PRINTERS                               
*****************************************************************/

const GET_AVAILABLE_FILAMENTS = gql`
  query Filament {
    filament {
      filamentId
      stock
      colour {
          colourId
          displayName
          hexCode
      }
      material {
          materialId
          displayName
          materialName
          materialCost
      }
    }
  }
`;

const GET_PRINTER_STATUS_FOR_FACTORY = gql`
  query PrintersInLocation($factoryId: ID!) {
    printersInLocation(factoryId: $factoryId) {
      printerId
      name
      displayNumber
      status
    }
  }
`;

const printers = {
  queries: {
    GET_AVAILABLE_FILAMENTS,
    GET_PRINTER_STATUS_FOR_FACTORY
  }
}

/*****************************************************************
                        PRINT REQUESTS                               
*****************************************************************/

const CREATE_PRINT_REQUEST = gql`
  mutation PrintRequest($fileId: ID!, $filamentId: ID!, $factoryId: ID!, $quantity: Int!, $productId: ID!) {
    printRequest(fileId: $fileId, filamentId: $filamentId, factoryId: $factoryId, quantity: $quantity, productId: $productId, emailNotifications: false) {
      success
      errors
      printRequest {
        requestId
      }
    }
  }
`;

const DELETE_PRINT_REQUEST = gql`
  mutation DeletePrintRequest($requestId: ID!) {
    deletePrintRequest(requestId: $requestId) {
      success
      errors
    }
  }
`;

// This will trigger the Scheduler to schedule the request onto an actual printer
const OPTIMIZE_SCHEDULE_FROM_REQUEST = gql`
  mutation OptimizeScheduleFromRequest($requestId: ID!) {
    optimizeScheduleFromRequest(requestId: $requestId) {
      success
      error
    }
  }
`;

const GET_PRINT_REQUESTS_FOR_USER = gql`
  query UserPrintRequests {
    userPrintRequests {
      requestId
      quantity
      jobsCompleted
      interupted
      issuedAt
      factory {
        factoryId
        name
      }
      file {
          fileId
          fileName
          file
          timestamp
          presignedUrl
          validUntil
          printable
      }
    }
  }
`;

const printRequests = {
  queries: {
    GET_PRINT_REQUESTS_FOR_USER,
  },
  mutations: {
    CREATE_PRINT_REQUEST,
    DELETE_PRINT_REQUEST,
    OPTIMIZE_SCHEDULE_FROM_REQUEST
  }
}

/*****************************************************************
                             MESSAGES                               
*****************************************************************/

const GET_MESSAGES_FOR_PRODUCT = gql`
  query MessagesForProduct($productId: ID!) {
    messagesForProduct(productId: $productId) {
      messageId
      message
      datetime
      read
      file {
        fileName
        presignedUrl
      }
      sender {
        userId
        username
      }      
    }
  }
`;

const ADD_MESSAGE_TO_PRODUCT = gql`
  mutation uploadMessage($productId: ID!, $message: String!, $emailNotifications: Boolean) {
    uploadMessage(productId: $productId, message: $message, emailNotifications: $emailNotifications) {
        success
        errors
    }
  }
`;

const ADD_MESSAGE_WITH_FILE_TO_PRODUCT = gql`
  mutation UploadMessageWithAttachment($productId: ID!, $message: String!, $file: Upload!, $emailNotifications: Boolean) {
    uploadMessageWithAttachment(productId: $productId, message: $message, file: $file, emailNotifications: $emailNotifications) {
      success
      errors
    }
  }
`;

const MARK_MESSAGE_AS_READ = gql`
  mutation readMessage($messageId: ID!) {
      readMessage(messageId: $messageId) {
          success
          errors
      }
  }
`;

const messages = {
  queries: {
    GET_MESSAGES_FOR_PRODUCT,
  },
  mutations: {
    ADD_MESSAGE_TO_PRODUCT,
    ADD_MESSAGE_WITH_FILE_TO_PRODUCT,
    MARK_MESSAGE_AS_READ,
  }
}


/*****************************************************************
                             USER                               
*****************************************************************/

const ALL_USERS = gql`
  query AllUsers {
    allUsers {
      lastLogin
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      dateJoined
      userId
      email
      username
    }
  }
`;

const USERNAMES = gql`
  query Usernames($username: String!) {
    usernames(username: $username) {
      userId
      username
    }
  }
`


const ME = gql`
  query Me {
    me {
      lastLogin
      firstName
      lastName
      isStaff
      isSuperuser
      isActive
      dateJoined
      userId
      email
      username
      id
      pk
      archived
      verified
      secondaryEmail
    }
  }
`;

const GET_USER_PREFERENCES = gql`
  query UserPreferences {
    userPreferences {
      id
      user {
        userId
      }
      reviewerMode
      sortHomeByProjects
    }
  }
`;

const LOGIN = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email:$email, password:$password)
    {
        token
        success
        user {
          userId
          username
          isStaff
          isSuperuser   
          firstName
          lastName
          verified
          userpreferencesmodelSet {
            reviewerMode
            sortHomeByProjects
          }
        }
    }
  }
`;

const REGISTER = gql`
  mutation Register($email: String!, $password1: String!, $password2: String!, $username: String!, $firstName: String!, $lastName: String!) {
    registerAccount(email:$email, password1:$password1, password2:$password2, username: $username, firstName: $firstName, lastName: $lastName) {
      success
      token
      errors
      user {
        userId
        username
        isStaff
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_MY_ACCOUNT = gql`
  mutation UpdateAccount($firstName: String, $lastName: String, $email: String, $username: String) {
    updateAccount(firstName: $firstName, lastName: $lastName, email: $email, username: $username) {
      success
      errors
    }
  }
`;


const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
      success
      errors
    }
  }
`;

const VERIFY_ACCOUNT = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation SendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation PasswordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
    }
  }
`;
const UPDATE_USER_PREFERENCES = gql`
  mutation UpdateUserPreferences($reviewerMode: Boolean, $sortHomeByProjects: Boolean) {
    updateUserPreferences(reviewerMode: $reviewerMode, sortHomeByProjects: $sortHomeByProjects) {
      success
      errors
      updatedUserPreferences {
        id
        user {
          userId
        }
        reviewerMode
        sortHomeByProjects
      }
    }
  }
`

function setToken(token: string) {
  const expires = new Date(Date.now() + 7 * 864e5).toUTCString(); // Expires in 7 days
  document.cookie = LOCAL ?
    `token=${encodeURIComponent(token)}; expires=${expires}; path=/; Secure; SameSite=Lax`
    : `token=${encodeURIComponent(token)}; expires=${expires}; path=/; domain=.batch.works; Secure; SameSite=Lax`
}

function getToken() {
  const name = 'token=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookiesArray = decodedCookie.split(';');
  for (let cookie of cookiesArray) {
    cookie = cookie.trim();
    if (cookie.startsWith(name)) {
      return cookie.substring(name.length);
    }
  }
  return '';
}

function isTokenSet() {
  return getToken() !== '';
}

function removeToken() {
  document.cookie = LOCAL ? 
    'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Lax'
    : 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.batch.works; Secure; SameSite=Lax'
}

const user = {
  queries: {
    ME,
    ALL_USERS,
    GET_USER_PREFERENCES,
    USERNAMES
  },
  mutations: {
    REGISTER,
    UPDATE_MY_ACCOUNT,
    LOGIN,
    VERIFY_TOKEN,
    VERIFY_ACCOUNT,
    SEND_PASSWORD_RESET_EMAIL,
    RESET_PASSWORD,
    UPDATE_USER_PREFERENCES,
  },
  local: {
    setToken,
    isTokenSet,
    getToken,
    removeToken,
  }
}

const api = {
  PING,
  GRAPHQL_URI,
  user,
  products,
  organisations,
  messages,
  printers,
  printRequests,
}

export default api;