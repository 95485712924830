import React from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import api from '../api/bw-api'

import { User, UserPreferences } from '@/types'


let user: User;

export const setUser = (user: User) => {
  user = user;
}

export const getUser = (): User => {
  return user;
}


export const useVerifyTokenAndGetUser = (): (token: string) => Promise<User> => {
  const [verifyTokenMutation] = useMutation(api.user.mutations.VERIFY_TOKEN);
  const [getUser] = useLazyQuery(api.user.queries.ME);
  const [getUserPreferences] = useLazyQuery(api.user.queries.GET_USER_PREFERENCES);

  return async (token: string): Promise<User> => {
    try {
      const verifyTokenResult = await verifyTokenMutation({ variables: { token } });
      if (verifyTokenResult.data.verifyToken.success) {
        const userResult = await getUser();
        const userPreferencesResult = await getUserPreferences();
        return {
          userId: userResult.data.me.userId,
          username: userResult.data.me.username,
          isStaff: userResult.data.me.isStaff,
          isSuperuser: userResult.data.me.isSuperuser,
          firstName: userResult.data.me.firstName,
          lastName: userResult.data.me.lastName,
          email: userResult.data.me.email,
          userPreferences: userPreferencesResult.data.userPreferences
        };
      } else {
        throw new Error("Token verification failed");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
};