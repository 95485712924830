import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { User } from '@/types';
import AuthContext from './context/auth';
import Items from '@/components/Items';
import api from './api/bw-api';
import { useVerifyTokenAndGetUser} from './services/user'
import { Bug, Library, Loader } from 'lucide-react';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const verifyTokenAndGetUser = useVerifyTokenAndGetUser();
  const local: boolean = process.env.REACT_APP_BRANCH === 'local'

  useEffect(() => {
    if (local) {
      setLoggedIn(true)
      return
    }
    const init = async ()  => { 
      if(api.user.local.isTokenSet()) {
        try {
          const userData: User = await verifyTokenAndGetUser(api.user.local.getToken())
          setUser(userData);
          setLoggedIn(true);
        } catch (err) {
          
        } finally {
          setLoading(false);
        }
      } else {
        window.location.href = 'https://beta.batch.works/login?redirect=gateway'
      }
    }

    init();
  }, [])
  return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, user, setUser }}>
      {
        loading &&
          <div className='w-screen h-screen flex items-center justify-center bg-gray-100'>
            <div className='flex items-center justify-center gap-2 text-sm'>
              <Loader className='h-4 w-4 animate-spin' />
              Authenticating
            </div>
          </div>
      }
      {(loggedIn && !loading) &&
        <div className='w-screen h-screen flex flex-col items-center justify-center bg-gray-100 gap-12'>
          <Items />
          <div className='bg-gray-500 h-0.5 w-12'></div>
          <div className='flex flex-col gap-4 items-center'>
            <a href='https://fast-celery-b5f.notion.site/Knowledge-Base-11903689332580deb48ad6a817085a8c'
              target='_blank'
              className='flex items-center gap-1 text-sm p-2 px-4 bg-gray-200 rounded-full uppercase font-medium hover:bg-black hover:text-white'>
                <Library className='h-4 w-4' /> Batch Knowledege Base
              </a>
            <a href='https://fast-celery-b5f.notion.site/Knowledge-Base-11903689332580deb48ad6a817085a8c'
              target='_blank'
              className='flex items-center gap-1 text-sm p-2 px-4 bg-gray-200 rounded-full uppercase font-medium w-min whitespace-nowrap hover:bg-black hover:text-white'>
                <Bug className='h-4 w-4' /> Report Bugs
              </a>
          </div>
        </div>
      }
    </AuthContext.Provider>
  );
};

export default App;
