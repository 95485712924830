// In context/auth.tsx
import React, { createContext, useContext } from 'react';
import { User } from '@/types';



type AuthContextType = {
  loggedIn: boolean;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

// Initialize context with default values.
const AuthContext = createContext<AuthContextType>({
  loggedIn: false,
  setLoggedIn: () => {},
  user: null,
  setUser: () => {}
});

// Create a custom hook to use the AuthContext, this is a best practice
export const useAuth = () => useContext(AuthContext);

export default AuthContext;
